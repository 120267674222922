/**
 * @generated SignedSource<<2bcf78d5287ebfe1f8d5509dcefd83ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getOrderStatusLabel_transaction$data = {
  readonly currentBuyerTimelineStatus: {
    readonly label: string | null;
  } | null;
  readonly currentSellerTimelineStatus: {
    readonly label: string | null;
  } | null;
  readonly isCanceled: boolean | null;
  readonly " $fragmentType": "getOrderStatusLabel_transaction";
};
export type getOrderStatusLabel_transaction$key = {
  readonly " $data"?: getOrderStatusLabel_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"getOrderStatusLabel_transaction">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getOrderStatusLabel_transaction"
};

(node as any).hash = "7e916b8db1164bc81428643c262c7112";

export default node;
