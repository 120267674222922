/**
 * @generated SignedSource<<452cc420047c854202eace861e65713c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrdersListOrder_transaction$data = {
  readonly checkoutDate: string | null;
  readonly item: {
    readonly firstPhotoWebPath: string | null;
    readonly title: string | null;
  } | null;
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"getOrderStatusLabel_transaction">;
  readonly " $fragmentType": "OrdersListOrder_transaction";
};
export type OrdersListOrder_transaction$key = {
  readonly " $data"?: OrdersListOrder_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrdersListOrder_transaction">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrdersListOrder_transaction",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkoutDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Item",
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "size",
              "value": "small"
            }
          ],
          "kind": "ScalarField",
          "name": "firstPhotoWebPath",
          "storageKey": "firstPhotoWebPath(size:\"small\")"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getOrderStatusLabel_transaction",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCanceled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TransactionCurrentBuyerTimelineStatus",
          "kind": "LinkedField",
          "name": "currentBuyerTimelineStatus",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TransactionCurrentSellerTimelineStatus",
          "kind": "LinkedField",
          "name": "currentSellerTimelineStatus",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Transaction",
  "abstractKey": null
};
})();

(node as any).hash = "1e18ab978c4d920fe9ca7196d3145836";

export default node;
