/**
 * @generated SignedSource<<8b028565517ed9dd9a0ee5d93d7666c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderInfo_viewer$data = {
  readonly getHelpTopic: {
    readonly showOrderTimeline: boolean | null;
  } | null;
  readonly transaction?: {
    readonly item: {
      readonly firstPhotoWebPath: string | null;
      readonly title: string | null;
    } | null;
    readonly serviceId: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"TransactionTimeline_transaction" | "getOrderStatusLabel_transaction">;
  } | null;
  readonly " $fragmentType": "OrderInfo_viewer";
};
export type OrderInfo_viewer$key = {
  readonly " $data"?: OrderInfo_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderInfo_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasOrderId"
    },
    {
      "kind": "RootArgument",
      "name": "isSeller"
    },
    {
      "kind": "RootArgument",
      "name": "orderId"
    },
    {
      "kind": "RootArgument",
      "name": "topicKey"
    },
    {
      "kind": "RootArgument",
      "name": "transactionId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderInfo_viewer",
  "selections": [
    {
      "condition": "hasOrderId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "transactionId",
              "variableName": "transactionId"
            }
          ],
          "concreteType": "Transaction",
          "kind": "LinkedField",
          "name": "transaction",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serviceId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "size",
                      "value": "thumb"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "firstPhotoWebPath",
                  "storageKey": "firstPhotoWebPath(size:\"thumb\")"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TransactionTimeline_transaction"
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "getOrderStatusLabel_transaction",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isCanceled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TransactionCurrentBuyerTimelineStatus",
                  "kind": "LinkedField",
                  "name": "currentBuyerTimelineStatus",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TransactionCurrentSellerTimelineStatus",
                  "kind": "LinkedField",
                  "name": "currentSellerTimelineStatus",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "isSeller",
          "variableName": "isSeller"
        },
        {
          "kind": "Variable",
          "name": "orderId",
          "variableName": "orderId"
        },
        {
          "kind": "Variable",
          "name": "topicKey",
          "variableName": "topicKey"
        }
      ],
      "concreteType": "GetHelpTopic",
      "kind": "LinkedField",
      "name": "getHelpTopic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showOrderTimeline",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "5864be03ce8cf12edec67653550b0985";

export default node;
