/**
 * @generated SignedSource<<20bc976638157d215c181efb88b64c4f>>
 * @relayHash 9cabcff86855ab10221d4c6af6179bda
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/483.0.0-2024-10-09T08:15:31.766Z/ContactSellerFormMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SupportedMFATypes = "CODE" | "%future added value";
export type ContactDealerInput = {
  action?: string | null;
  attempt?: number | null;
  buyerId: string;
  captchaKey: string;
  captchaToken: string;
  clientMutationId?: string | null;
  currencyConversionRateEventId?: string | null;
  displayName?: string | null;
  isOptIn?: boolean | null;
  itemPk?: string | null;
  messageContent: string;
  mfaVerificationCode?: string | null;
  opopABTestBucket?: string | null;
  pageContext?: string | null;
  sellerPk: string;
  supportRequestTypeId?: string | null;
  supportedMFATypes?: ReadonlyArray<SupportedMFATypes | null> | null;
};
export type ContactSellerFormMutation$variables = {
  input: ContactDealerInput;
};
export type ContactSellerFormMutation$data = {
  readonly contactDealer: {
    readonly conversation: {
      readonly serviceId: string | null;
    } | null;
  } | null;
};
export type ContactSellerFormMutation = {
  response: ContactSellerFormMutation$data;
  variables: ContactSellerFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactSellerFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContactDealerPayload",
        "kind": "LinkedField",
        "name": "contactDealer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Conversation",
            "kind": "LinkedField",
            "name": "conversation",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContactSellerFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContactDealerPayload",
        "kind": "LinkedField",
        "name": "contactDealer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Conversation",
            "kind": "LinkedField",
            "name": "conversation",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/483.0.0-2024-10-09T08:15:31.766Z/ContactSellerFormMutation",
    "metadata": {},
    "name": "ContactSellerFormMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "c35a28c3adc1fe82eecae47016cf5a7e";

export default node;
