/**
 * @generated SignedSource<<c86f79ff43fef15b364fd91ef0665b4c>>
 * @relayHash 04c102668e6ab83c7b09ad07d63a3c6c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/483.0.0-2024-10-09T08:15:31.766Z/GetHelpZendeskEmbeddedQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetHelpZendeskEmbeddedQuery$variables = {
  articleId?: string | null;
  hasSellerId: boolean;
  hasUserId: boolean;
  isSeller?: boolean | null;
  sellerId: string;
  userId: string;
};
export type GetHelpZendeskEmbeddedQuery$data = {
  readonly viewer: {
    readonly contact1stdibsConfig: {
      readonly isAgentAvailable: boolean | null;
      readonly showChannelChat: boolean | null;
    } | null;
    readonly user?: {
      readonly profile: {
        readonly firstName: string | null;
      } | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"GetHelpZendeskContactSupport_viewer">;
  };
};
export type GetHelpZendeskEmbeddedQuery = {
  response: GetHelpZendeskEmbeddedQuery$data;
  variables: GetHelpZendeskEmbeddedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "articleId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hasSellerId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hasUserId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isSeller"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sellerId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "isSeller",
      "variableName": "isSeller"
    }
  ],
  "concreteType": "Contact1stdibsConfig",
  "kind": "LinkedField",
  "name": "contact1stdibsConfig",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAgentAvailable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showChannelChat",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetHelpZendeskEmbeddedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Profile",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GetHelpZendeskContactSupport_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetHelpZendeskEmbeddedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Profile",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v3/*: any*/),
          {
            "condition": "hasSellerId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "sellerId",
                    "variableName": "sellerId"
                  }
                ],
                "concreteType": "Seller",
                "kind": "LinkedField",
                "name": "seller",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SellerProfile",
                    "kind": "LinkedField",
                    "name": "sellerProfile",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact1stdibsZendeskArticles",
            "kind": "LinkedField",
            "name": "zendeskArticles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "articleId",
                    "variableName": "articleId"
                  }
                ],
                "kind": "ScalarField",
                "name": "topicKeyForArticle",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/483.0.0-2024-10-09T08:15:31.766Z/GetHelpZendeskEmbeddedQuery",
    "metadata": {},
    "name": "GetHelpZendeskEmbeddedQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "87139073ee325bbaa28a139691b013b6";

export default node;
