import { FC, useContext } from 'react';
import { useFragment, graphql } from 'react-relay';
import classnames from 'classnames';
import { FormattedMessage } from 'dibs-react-intl';

import { Button } from 'dibs-elements/exports/Button';

import { trackHelpWithAnOrderClick } from './tracking';
import GetHelpContext from './GetHelpContext';
import {
    ACTION_SET_STEP,
    ACTION_SET_HELP_TYPE,
    STEP_ORDERS_LIST,
    STEP_OTHER_ASSISTANCE,
    HELP_TYPE_ORDER_ISSUES,
    HELP_TYPE_OTHER_ASSISTANCE,
    RENDER_AS_LOGGED_OUT,
} from './constants';

import { IntroStep_viewer$key } from './__generated__/IntroStep_viewer.graphql';

import styles from './styles/IntroStep.scss';
import { STEP_SELECTED_ORDER } from './constants';

const IntroStep: FC<{ viewer: IntroStep_viewer$key }> = ({ viewer: viewerRef }) => {
    const {
        dispatch,
        state: { renderAs, showAuthModal, orderId, isEmbedded },
    } = useContext(GetHelpContext);

    const viewer = useFragment(
        graphql`
            fragment IntroStep_viewer on Viewer {
                user(userId: $userId) @include(if: $hasUserId) {
                    profile {
                        firstName
                    }
                }
            }
        `,
        viewerRef
    );

    const { firstName } = viewer.user?.profile || {};

    return (
        <div className={classnames(styles.container, { [styles.isEmbedded]: isEmbedded })}>
            <div className={styles.containerInner}>
                <div className={styles.header} data-tn="get-help-modal-intro-step-greeting">
                    {firstName ? (
                        <FormattedMessage
                            id="getHelp.IntroStep.introHeaderLoggedIn"
                            defaultMessage="Hi {firstName}, what can we help you with?"
                            values={{
                                firstName: <span className={styles.firstName}>{firstName}</span>,
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="getHelp.IntroStep.introHeader"
                            defaultMessage="What can we help you with?"
                        />
                    )}
                </div>
                <Button
                    dataTn="get-help-modal-order-issues-cta"
                    onClick={() => {
                        dispatch({ type: ACTION_SET_HELP_TYPE, helpType: HELP_TYPE_ORDER_ISSUES });
                        if (renderAs === RENDER_AS_LOGGED_OUT) {
                            if (showAuthModal) {
                                showAuthModal({
                                    step: STEP_ORDERS_LIST,
                                    helpType: HELP_TYPE_ORDER_ISSUES,
                                });
                            }
                        } else {
                            dispatch({
                                type: ACTION_SET_STEP,
                                step: orderId ? STEP_SELECTED_ORDER : STEP_ORDERS_LIST,
                            });
                        }
                        trackHelpWithAnOrderClick();
                    }}
                    className={styles.cta}
                >
                    <FormattedMessage
                        id="getHelp.IntroStep.helpWithOrderCta"
                        defaultMessage="Help with an order"
                    />
                </Button>

                <Button
                    dataTn="get-help-modal-other-assistance-cta"
                    onClick={() => {
                        dispatch({ type: ACTION_SET_STEP, step: STEP_OTHER_ASSISTANCE });
                        dispatch({
                            type: ACTION_SET_HELP_TYPE,
                            helpType: HELP_TYPE_OTHER_ASSISTANCE,
                        });
                    }}
                    className={styles.cta}
                    type="transparent"
                >
                    <FormattedMessage
                        id="getHelp.IntroStep.otherAssistanceCta"
                        defaultMessage="I need other assistance"
                    />
                </Button>
            </div>
        </div>
    );
};

export default IntroStep;
