/**
 * @generated SignedSource<<10d773372912e05c5496afd7e7f787f7>>
 * @relayHash 09f087f7d6fbd6198abff87ea56d20f2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/483.0.0-2024-10-09T08:15:31.766Z/OrdersListQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrdersListQuery$variables = {
  excludeCanceled: boolean;
  hasSellerId: boolean;
  hasUserId: boolean;
  searchTerm: string;
  sellerId: string;
  userId: string;
  viewName?: string | null;
};
export type OrdersListQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"OrdersList_viewer">;
  };
};
export type OrdersListQuery = {
  response: OrdersListQuery$data;
  variables: OrdersListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeCanceled"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasSellerId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sellerId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewName"
},
v7 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "OrdersList_viewer"
  }
],
v8 = [
  {
    "kind": "Literal",
    "name": "after",
    "value": ""
  },
  {
    "kind": "Variable",
    "name": "excludeCanceled",
    "variableName": "excludeCanceled"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Variable",
    "name": "searchTerm",
    "variableName": "searchTerm"
  },
  {
    "kind": "Variable",
    "name": "sellerId",
    "variableName": "sellerId"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  },
  {
    "kind": "Variable",
    "name": "viewName",
    "variableName": "viewName"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  }
],
v11 = [
  {
    "alias": null,
    "args": (v8/*: any*/),
    "concreteType": "TransactionsPaginatedSearchConnection",
    "kind": "LinkedField",
    "name": "transactionsPaginatedSearch",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numberOfPages",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TransactionsPaginatedSearchEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Transaction",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "checkoutDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Item",
                "kind": "LinkedField",
                "name": "item",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "size",
                        "value": "small"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "firstPhotoWebPath",
                    "storageKey": "firstPhotoWebPath(size:\"small\")"
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isCanceled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TransactionCurrentBuyerTimelineStatus",
                "kind": "LinkedField",
                "name": "currentBuyerTimelineStatus",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TransactionCurrentSellerTimelineStatus",
                "kind": "LinkedField",
                "name": "currentSellerTimelineStatus",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v8/*: any*/),
    "filters": [
      "userId",
      "sellerId",
      "searchTerm",
      "viewName",
      "excludeCanceled"
    ],
    "handle": "connection",
    "key": "OrdersList_transactionsPaginatedSearch",
    "kind": "LinkedHandle",
    "name": "transactionsPaginatedSearch"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrdersListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": (v7/*: any*/)
          },
          {
            "condition": "hasSellerId",
            "kind": "Condition",
            "passingValue": true,
            "selections": (v7/*: any*/)
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrdersListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": (v11/*: any*/)
          },
          {
            "condition": "hasSellerId",
            "kind": "Condition",
            "passingValue": true,
            "selections": (v11/*: any*/)
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/483.0.0-2024-10-09T08:15:31.766Z/OrdersListQuery",
    "metadata": {},
    "name": "OrdersListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "71831222996146b76e665aa290b2f3a9";

export default node;
