import { localStorage } from 'dibs-browser-storage';
import { State, ActionType } from './GetHelpZendeskTypes';
import {
    STEPS,
    ACTIONS,
    RENDER_AS,
    GET_HELP_MODAL_ZENDESK_OPTIONS,
} from './GetHelpZendeskConstants';
import { GetHelpModalZendeskOptions } from './GetHelpZendeskTypes';
import { getGetHelpZendeskLocalStorageState } from './getHelpZendeskHelpers';

const setLocalStorageState = (newState: Partial<GetHelpModalZendeskOptions>): void => {
    localStorage.setItem(GET_HELP_MODAL_ZENDESK_OPTIONS, {
        ...getGetHelpZendeskLocalStorageState(),
        ...newState,
    });
};

export const initialState: State = {
    step: STEPS.INTRO,
    stepsPath: [{ step: STEPS.INTRO }],
    renderAs: RENDER_AS.CONSUMER,
    onClose: () => {},
    isOpen: false,
    isEmbedded: false,
    userId: '',
    placement: 'globalNav',
    sellerId: '',
    isSeller: false,
    orderId: '',
    articleId: '',
    searchQuery: '',
    topic: '',
};

const scrollToTop = (): void => {
    const modalElement = document.querySelector(`[data-tn='get-help-zendesk-modal']`);

    if (modalElement) {
        modalElement.scrollTop = 0;
    }
};

export function getHelpZendeskReducer(state: State, action: ActionType): State {
    switch (action.type) {
        case ACTIONS.SET_STEP: {
            scrollToTop();
            const updatedState = {
                step: action.step,
                stepsPath: [...state.stepsPath, { step: action.step }],
            };

            setLocalStorageState(updatedState);

            return {
                ...state,
                ...updatedState,
            };
        }
        case ACTIONS.OPEN_ARTICLE: {
            scrollToTop();
            const updatedState = {
                articleId: action.articleId,
                step: STEPS.ARTICLE,
                stepsPath: [
                    ...state.stepsPath,
                    { step: STEPS.ARTICLE, articleId: action.articleId },
                ],
                searchQuery: '',
            };

            setLocalStorageState(updatedState);

            return {
                ...state,
                ...updatedState,
            };
        }
        case ACTIONS.GO_BACK: {
            scrollToTop();
            state.stepsPath.pop();
            const lastStep = state.stepsPath.slice(-1)[0] || {};
            let updatedState = {};

            if (lastStep.step === STEPS.ARTICLE) {
                updatedState = { articleId: lastStep?.articleId || state.articleId };
            }

            if (lastStep.step === STEPS.SEARCH_RESULTS) {
                updatedState = { searchQuery: lastStep?.searchQuery || state.searchQuery };
            } else {
                updatedState = { ...updatedState, searchQuery: '' };
            }

            updatedState = {
                ...updatedState,
                stepsPath: state.stepsPath,
                step: lastStep.step || STEPS.INTRO,
            };

            setLocalStorageState(updatedState);

            return {
                ...state,
                ...updatedState,
            };
        }
        case ACTIONS.SET_ORDER_ID: {
            setLocalStorageState({ orderId: action.orderId });

            return {
                ...state,
                orderId: action.orderId,
            };
        }

        case ACTIONS.SET_SEARCH_RESULTS_STEP: {
            scrollToTop();
            let updatedState = {};

            updatedState = {
                ...updatedState,
                searchQuery: action.searchQuery,
                step: STEPS.SEARCH_RESULTS,
                stepsPath: [
                    ...state.stepsPath,
                    {
                        step: STEPS.SEARCH_RESULTS,
                        searchQuery: action.searchQuery,
                    },
                ],
            };

            setLocalStorageState(updatedState);

            return {
                ...state,
                ...updatedState,
            };
        }
        case ACTIONS.SET_TOPIC: {
            setLocalStorageState({ topic: action.topic });

            return {
                ...state,
                topic: action.topic,
            };
        }
        default: {
            return state;
        }
    }
}
