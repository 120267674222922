import { FC, Suspense, useContext } from 'react';

import { ErrorBoundary } from 'dibs-error-tools/ErrorBoundary';
import { ModalCloseButton } from 'dibs-elements/exports/ModalCloseButton';
import { ModalContainer } from 'dibs-elements/exports/ModalContainer';
import { Spinner } from 'dibs-elements/exports/Spinner';

import GetHelpRenderer from './GetHelpRenderer';
import Error from './Error';

import GetHelpContext from './GetHelpContext';

import styles from './styles/GetHelpModal.scss';

const GetHelpModal: FC = () => {
    const {
        state: { isOpen, onClose },
    } = useContext(GetHelpContext);

    return (
        // note: this data-tn is also used in packages/dibs-contact-1stdibs/src/OtherHelp.tsx to modify scroll position
        <ModalContainer
            isOpen={isOpen}
            onClose={onClose}
            modalPosition="center"
            dataTn="get-help-modal"
            modalSize="medium"
            modalClass={styles.modalContainer}
        >
            <ModalCloseButton onClick={onClose} dataTn="get-help-modal-close-button" />
            <ErrorBoundary fallback={() => <Error />}>
                <Suspense
                    fallback={
                        <div className={styles.spinnerWrapper}>
                            <Spinner />
                        </div>
                    }
                >
                    <GetHelpRenderer />
                </Suspense>
            </ErrorBoundary>
        </ModalContainer>
    );
};

export default GetHelpModal;
